/* App.css */
body {
  font-family: Arial, sans-serif;
}

input, select, button {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
}

button:hover {
  opacity: 0.8;
}

label {
  display: block;
  margin-top: 20px;
}
